<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="teams"
      sort-by="t_start_position"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Alla lag</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Ladda upp lag
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Välj CSV-filen</span>
              </v-card-title>

              <v-card-text>
                <!-- TODO: Validation with vee-validate  -->
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <!-- Accept any image or video -->
                    <v-file-input
                      accept=".csv"
                      label="massa_goda_lag.csv"
                      @change="uploadCSV"
                      required
                    ></v-file-input>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Avbryt
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Ladda upp
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex"
import {notify} from '../../../utils/notification'

export default {
  name: 'Teams',
  data: () => ({
    dialog: false,
    headers: [
      {
        text: 'Startnummer',
        align: 'start',
        sortable: false,
        value: 't_start_position',
      },
      { text: 'Lagnamn', value: 't_name' },
      { text: 'Lagledare', value: 't_leader' },
      { text: 'Mobilnmr.', value: 't_phone' },
      { text: 'Email', value: 't_email' },
      { text: 'Deltagare', value: 't_nr_participants' },
      { text: 'Lagbeskrivning', value: 't_description' },
      { text: 'Kopplingskod', value: 't_token' }
    ],
    teams: [],
    fileData: null
  }),

  computed: {
    ...mapGetters(["notification"]),
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions(["getAllTeams", "createUsersAndTeams", "clearNotifications"]),
    async initialize () {
      const teams = await this.getAllTeams()
      // console.log(teams)
      // Reset
      this.teams = []
      // Filter teams
      teams.forEach((team) => {
        this.teams.push({
          t_start_position: team.t_start_position,
          t_name: team.t_name,
          t_leader: team.t_leader,
          t_phone: team.t_phone,
          t_email: team.t_email,
          t_nr_participants: team.t_nr_participants,
          t_description: team.t_description,
          t_token: team.t_token
        })
      })
    },
    close () {
      this.dialog = false
    },
    uploadCSV(file) {
      // console.log(file)
      let formData = new FormData()
      formData.append("file", file)

      this.fileData = formData
    },
    async save () {
      await this.createUsersAndTeams(this.fileData)

      // Notification
      notify(this)
    },
  },
  computed: mapGetters(["notification"])
}
</script>

<style>

</style>